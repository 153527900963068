import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../../Headers/Header';
import LoaderPage from '../LoaderPage';
import { fetchSingleCourse,updateCourse } from "../../../crud/common.crud";
import { Card,Container,Row,Form,Button } from 'react-bootstrap-v5';

const CourseEdit = () => {

    const loggedin = JSON.parse(localStorage.getItem("adminLoggedin"));
    const slug = JSON.parse(localStorage.getItem("courseSlug"));
    const [couseName, setCouseName] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [couseTenure, setCouseTenure] = useState('');
    const [noSingleModule, setNoSingleModule] = useState(false);
    const [cPrice, setCPrice] = useState(0);
    const [sPrice, setSPrice] = useState(0);
    const [ redirect, setRedirect ]= useState(false);
    useEffect(() => {    
        fetchSingleCourse(slug).then(res => {
            // const cccData = res.data;
            setCouseName(res.data.couseName);    
            setCouseTenure(res.data.couseTenure);   
            setNoSingleModule(res.data.noSingleModule);   
            setCPrice(res.data.coursePrice.corporatePrice);   
            setSPrice(res.data.coursePrice.selfPrice);   
            setIsDataLoaded(true);
        }).catch((err)=>{
            console.log(err);
        });
    }, [slug]);

    const handleSubmit=(e) => {
        e.preventDefault();  
        updateCourse(slug,couseName,couseTenure,noSingleModule,cPrice,sPrice).then((res)=>{
            setRedirect(true);
        }).catch((err)=>{
            console.log(err);
        });
    };


    if(!loggedin) {
        return <Navigate to="/" />;
    }
    if(redirect) {
        return <Navigate to="/courses" />;
    }

    return(
        <>
        {!isDataLoaded? (
            <LoaderPage />
        ) : (
                <>
                    <Header /><br/>
                    <div className="bg-primary">
                        <h5 className="text-center text-white p-4">
                            Edit Basic Details for: <b className="text-warning">{ couseName }</b>
                        </h5>
                    </div>
                    <Container>
                        {/* <h2>Edit Basic Details for {couseName}</h2> */}
                        <Card md="10" className='p-2 mt-3'>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formCourse">
                                    <Form.Label>Course Name</Form.Label>
                                    <Form.Control type="text" 
                                        defaultValue={couseName}
                                        onChange={(e)=>{setCouseName(e.target.value)}} />  
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formTenure">
                                    <Form.Label>Course Tenure in Months</Form.Label>
                                    <Form.Control type="text" 
                                        defaultValue={couseTenure}
                                        onChange={(e)=>{setCouseTenure(e.target.value)}} />  
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <div className="col-6">
                                            <Form.Label>Total Course Corporate Price</Form.Label>
                                            <Form.Control type="number" 
                                                value={cPrice}
                                                onChange={(e)=>{setCPrice(e.target.value)}} />  
                                        </div> 
                                        <div className="col-6">
                                            <Form.Label>Total Course Self Price</Form.Label>
                                            <Form.Control type="number" 
                                                value={sPrice}
                                                onChange={(e)=>{setSPrice(e.target.value)}} />  
                                        </div>
                                    </Row> 
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="checkbox">
                                    <Form.Label>Course have no single module price</Form.Label>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="checkbox" 
                                        checked={noSingleModule? "checked":""}
                                        // defaultValue={course.noSingleModule} 
                                        // onLoad={(e)=>{setNoSingleModule(e.target.value)} }
                                        onChange={(e)=>{setNoSingleModule(!noSingleModule)} }
                                    />
                                </Form.Group>
                    
                                <Form.Group className="mb-3" controlId="buttons">
                                    <a className="btn btn-danger btn-sm" href="/courses">Cancel</a>
                                    <Button className="btn btn-success btn-sm" type="submit">Update</Button>
                                </Form.Group>

                            </Form>
                        </Card>
                    </Container>   
                </>
            ) };
        </>
    )
}
export default CourseEdit;