import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Pages/Dashboard/Dashboard';
import BackendUsers from './components/Pages/BackUser/BackendUsers';
import Courses from './components/Pages/Courses/Courses';
import CourseDetails from './components/Pages/Courses/CourseDetails';
import CourseEdit from './components/Pages/Courses/CourseEdit';
import CourseModuleEdit from './components/Pages/Courses/CourseModuleEdit';
import Students from './components/Pages/Students/Students';
import EditStudent from './components/Pages/Students/EditStudent';
import Logout from './components/Pages/Logout';
import Login from './components/Pages/Login';
import StudentDetails from './components/Pages/Students/StudentDetails';

function App() {
 
  return (
    <div className="App">      
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />}/>
          <Route exact path="/dashboard" element={<Dashboard />}/>
          <Route exact path="/courses" element={<Courses />}/>
          <Route exact path="/courseDetails" element={<CourseDetails />}/>
          <Route exact path="/courseEdit" element={<CourseEdit />}/>
          <Route exact path="/courseModuleEdit" element={<CourseModuleEdit />}/>          
          <Route exact path="/students" element={<Students />}/>
          <Route exact path="/editStudent" element={<EditStudent />}/>
          <Route exact path="/StudentDetails" element={<StudentDetails />}/>
          <Route exact path="/users" element={<BackendUsers />}/>
          <Route exact path="/logout" element={<Logout />}/>
        </Routes>
      </Router>
    </div>
  )
}
export default App;
