import React from 'react';
import { Nav } from "react-bootstrap-v5";
function Header() {
    return(
        <Nav className="navbar navbar-dark bg-primary justify-content-between text-white">
            <Nav.Item>
                <Nav.Link href="/dashboard" className="text-white">Dashboard</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/courses" className="text-white">Courses</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/students" className="text-white">Students</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/users" className="text-white">Users</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/logout" className="text-white">Logout</Nav.Link>
            </Nav.Item>

        </Nav>
    )
}
export default Header;