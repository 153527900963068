import React, { useState,useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../../Headers/Header';
import LoaderPage from '../LoaderPage';
import { fetchSingleStudent } from "../../../crud/common.crud";
import dp from '../../images/common_dp.png';
import { Card,Container,Row,Button,Tabs,Tab,TabContent,Col,Badge,ListGroup,ListGroupItem  } from 'react-bootstrap-v5';

const StudentDetails =(props) => {

    const loggedin = JSON.parse(localStorage.getItem("adminLoggedin"));
    const stdEmail = JSON.parse(localStorage.getItem("stdEmail"));
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [purCourses, setPurCourses] = useState([]);
    const [lerCourses, setLerCourses] = useState([]);
    const [comCourses, setComCourses] = useState([]);
    const [purCourseMods, setPurCourseMods] = useState([]);
    const [lerCourseMods, setLerCourseMods] = useState([]);
    const [comCourseMods, setComCourseMods] = useState([]);
    const [student, setStudent] = useState({
        studentID: '',
        studentName: '',
        stdFHName: '',
        studentPicture: '',
        studentAddress: {
            address: '',
            city: '',
            pin: 0
        },
        studentDob: '',
        studentGender: '',
        studentEmail: '',
        studentPassword: '',
        source: '',
        studentPhone: {
            mobile: 0,
            whatsapp: 0
        },
        studentProfDtls: {
            medRegNo: '',
            practiceArea: '',
            eduQualification:''
        },
        enrolledCourses:[{
            courseName: '',
            courseSlug: '',
            fullCourseTaken: false,
            modules:[{
                modID : '',
                moduleName : '',
                preQusetions:[{  
                    question : '',
                    option1 : '',
                    option2 : '',
                    option3 : '',
                    option4 : '',
                    oriAnswer : '',
                    priAnswer : '',
                    postAnswer : '',
                }],
                preStatus: false,
                postStatus: false,
                finalStatus: false,
                preNumber: 0,
                postNumber: 0,
                finalNumber: 0,
                preTime: 0,
                postTime: 0,
                finalTime: 0,
            }],
            paymentStatus: '',
            paid: 0
        }],
        studentPermission: false
    });
    const calculationsss = (val) => {
        for(let c=0,t=val.enrolledCourses.length;c<t;c++) {
            let lerCheck=false,comCheck=true;
            let purCr=val.enrolledCourses[c].courseName;
            setPurCourses((purCourses) => [...purCourses,purCr]);//all purchased courses
            let allMods=[];
            let lerMods=[];
            let comMods=[];
            for(let l=0,lc=val.enrolledCourses[c].modules.length;l<lc;l++) {
                allMods[l] = { 
                    modName:val.enrolledCourses[c].modules[l].moduleName,
                    preScore:val.enrolledCourses[c].modules[l].preNumber,
                    postScore1:val.enrolledCourses[c].modules[l].postNumber,
                    postScore2:val.enrolledCourses[c].modules[l].postNumber,
                    postScore3:val.enrolledCourses[c].modules[l].postNumber,
                    exitcore1:val.enrolledCourses[c].modules[l].finalNumber,
                    exitcore2:val.enrolledCourses[c].modules[l].finalNumber,
                    exitcore3:val.enrolledCourses[c].modules[l].finalNumber,
                };
                if(val.enrolledCourses[c].modules[l].preStatus) { 
                    let llength=lerMods.length;
                    lerMods[llength] = { modName:val.enrolledCourses[c].modules[l].moduleName };
                    lerCheck=true; 
                }
                if(!val.enrolledCourses[c].modules[l].finalStatus) { comCheck=false; }
            }
            setPurCourseMods((purCourseMods) => [...purCourseMods,allMods]);
            setLerCourseMods((lerCourseMods) => [...lerCourseMods,lerMods]);
            setComCourseMods((comCourseMods) => [...comCourseMods,comMods]);
            if(lerCheck) {setLerCourses((lerCourses) => [...lerCourses,purCr]);}//all purchased courses
            if(comCheck) {setComCourses((comCourses) => [...comCourses,purCr]);}//all purchased courses
        }
        // console.log("======"+purCourseMods[0]);
    };
    
    useEffect(() => {
        fetchSingleStudent(stdEmail).then(res => {
            setStudent(res.data);
            console.log(res.data);
            calculationsss(res.data);
            setIsDataLoaded(true);
        });
    }, [stdEmail]);

    if(!loggedin) {
        return <Navigate to="/" />;
    }
    return(
        <>
            {!isDataLoaded? (
                <LoaderPage />
            ) : (
                <>
                    <Header />
                    <Container>
                        <h2>{props.slug}</h2>
                        <Row>
                            <Card className="p-0" style={{borderRadius:'15px', boxShadow:'2px 2px 20px #666'}}>
                                <Card.Body>
                                    <Row className="justify-content-md-center mx-auto">
                                        <Col md={3} className="float-start rounded shadow border border-5">
                                            <Row className='text-center py-2'>
                                                <Col><Badge pill bg="danger">REG NO: {student.studentID}</Badge></Col>
                                                <Col><Badge pill bg="success">D.O.B: 21/09/1991</Badge></Col>    
                                            </Row>
                                            <Row className="justify-content-md-center my-4">
                                                <img 
                                                className="img-fluid rounded-circle shadow mx-auto" 
                                                style={{width:'40%', border:'2px solid #0defcc', padding:'5px'}} 
                                                src={dp} 
                                                alt="username"
                                                />    
                                            </Row>
                                            <Card.Title className="text-center">{student.studentName} ({student.studentGender})</Card.Title>
                                            <a href="tel:{student.studentPhone.mobile}">
                                                <h6 className="text-center"><i className="fa fa-phone"> {student.studentPhone.mobile}</i></h6>
                                            </a>
                                            <h6 className="text-center">{student.studentAddress.address}, {student.studentAddress.city} -{student.studentAddress.pin}</h6>
                                        </Col>
                                        <Col md={4} className=" my-auto">
                                            <ListGroup className=" border shadow rounded text-center">
                                                <ListGroupItem className="bg-dark text-white">Student Guardian Name : {student.stdFHName}</ListGroupItem>
                                                <ListGroupItem className="bg-info">City : {student.studentAddress.city}</ListGroupItem>
                                                <ListGroupItem className="bg-dark text-white">Pin : {student.studentAddress.pin}</ListGroupItem>
                                                <ListGroupItem className="bg-info">Email : {student.studentEmail}</ListGroupItem>
                                            </ListGroup>
                                        </Col>
                                        <Col md={4} className="my-auto">
                                            <ListGroup className=" border shadow rounded text-center">
                                                <ListGroupItem className="bg-dark text-white">Whatsapp : {student.studentPhone.whatsapp}</ListGroupItem>
                                                <ListGroupItem className="bg-info">Professional Educational Qualification : {student.studentProfDtls.eduQualification}</ListGroupItem>
                                                <ListGroupItem className="bg-dark text-white">Medical Registration No : {student.studentProfDtls.medRegNo}</ListGroupItem>
                                                <ListGroupItem className="bg-info">Area of Practice : {student.studentProfDtls.practiceArea} </ListGroupItem>
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <small className="text-muted">Last updated 3 mins ago</small>
                                </Card.Footer>
                            </Card>                 
                        </Row><br/>
                        <Row className=" "  style={{borderRadius:'15px', boxShadow:'2px 2px 20px #666'}}>
                        <Tabs defaultActiveKey="course" id="uncontrolled-tab-example" className="mb-3 justify-content-md-center">
                            <Tab eventKey="course" title="Course">
                                <ListGroup className=" border shadow rounded text-center">
                                    <ListGroupItem><Button variant="primary">Purchased <Badge bg="secondary">{purCourses.length}</Badge></Button> : 
                                        {purCourses?.map((pcc,pc) =>
                                            <Badge pill bg="info" key={pc}>{pcc}</Badge> 
                                        )}
                                    </ListGroupItem>
                                    <ListGroupItem><Button variant="primary">Learning <Badge bg="secondary">{lerCourses.length}</Badge></Button> : 
                                        {lerCourses?.map((lcc,lc) =>
                                            <Badge pill bg="warning" key={lc}>{lcc}</Badge> 
                                        )}
                                    </ListGroupItem>
                                    <ListGroupItem><Button variant="primary">Completed <Badge bg="secondary">{comCourses.length}</Badge></Button> : 
                                        {comCourses?.map((ccc,cc) =>
                                            <Badge pill bg="success" key={cc}>{ccc}</Badge> 
                                        )}
                                    </ListGroupItem>
                                </ListGroup>
                                <TabContent /><br/>
                            </Tab>
                            <Tab eventKey="module" title="Module">
                                <Row>
                                    {student.enrolledCourses?.map((crs,crsCount) =>
                                        <Col key={crsCount} md={4}>
                                            <ListGroup className=" border shadow rounded text-center">
                                                <ListGroupItem>{crs.courseName}</ListGroupItem>
                                                <ListGroupItem><Button variant="primary">Purchased <Badge bg="secondary"></Badge></Button> : 
                                                {/* <ListGroupItem><Button variant="primary">Purchased <Badge bg="secondary">{(purCourseMods[crsCount]).length}</Badge></Button> :  */}
                                                    {purCourseMods[crsCount]?.map((pcmm,pcm) =>
                                                        <Badge pill bg="info" key={pcm}>{pcmm.modName}</Badge> 
                                                    )}   
                                                </ListGroupItem>
                                                <ListGroupItem><Button variant="primary">Learning <Badge bg="secondary"></Badge></Button> : 
                                                    {lerCourseMods[crsCount]?.map((lcmm,lcm) =>
                                                        <Badge pill bg="warning" key={lcm}>{lcmm.modName}</Badge> 
                                                    )}    
                                                </ListGroupItem>
                                                <ListGroupItem><Button variant="primary">Completed <Badge bg="secondary"></Badge></Button> : 
                                                    {comCourseMods[crsCount]?.map((ccmm,ccm) =>
                                                        <Badge pill bg="success" key={ccm}>{ccmm.modName}</Badge> 
                                                    )}   
                                                </ListGroupItem>
                                            </ListGroup>
                                        </Col>
                                    )}
                                    
                                </Row>
                            <TabContent /><br/>
                            </Tab>
                            <Tab eventKey="exam" title="Exam">
                                {student.enrolledCourses.length &&
                                    <Tabs defaultActiveKey={student.enrolledCourses[0].courseName} id="uncontrolled1-tab-example" className="mb-3 justify-content-md-center">
                                        {student.enrolledCourses?.map((crs,crsCount) =>
                                            <Tab eventKey={crs.courseName} title={crs.courseName} key={crsCount}>
                                                
                                                <Row>
                                                    {purCourseMods[crsCount]?.map((pcmm,pcm) =>
                                                        <Col key={pcm} md={4}>
                                                            <ListGroup className=" border shadow rounded text-center">
                                                                <ListGroupItem>{crs.courseName} | {pcmm.modName}</ListGroupItem>
                                                                <ListGroupItem><Button variant="primary">Pre Test </Button> :  
                                                                    <Badge pill bg="info">{pcmm.preNumber}</Badge> 
                                                                </ListGroupItem>
                                                                <ListGroupItem><Button variant="primary">Post Test </Button> : 
                                                                    <Badge pill bg="warning">{pcmm.postScore1}</Badge> 
                                                                    <Badge pill bg="warning">{pcmm.postScore2}</Badge> 
                                                                    <Badge pill bg="warning">{pcmm.postScore3}</Badge> 
                                                                </ListGroupItem>
                                                                <ListGroupItem><Button variant="primary">Exit Test </Button> : 
                                                                    <Badge pill bg="success">{pcmm.exitcore1}</Badge> 
                                                                    <Badge pill bg="success">{pcmm.exitcore2}</Badge> 
                                                                    <Badge pill bg="success">{pcmm.exitcore3}</Badge>
                                                                </ListGroupItem>
                                                            </ListGroup>
                                                        </Col>
                                                    )}
                                                    
                                                </Row>
                                            </Tab>
                                        )}                            
                                    </Tabs>
                                }
                            <TabContent /><br/>
                            </Tab>
                            <Tab eventKey="payment" title="Payment">
                                <ListGroup className=" border shadow rounded text-center">
                                    <ListGroupItem><Button variant="primary">Diatech Basic <Badge bg="secondary">All</Badge></Button> : 
                                            <Badge pill bg="info">12/09/2021</Badge> 
                                            <Badge pill bg="info">
                                                <i className="fa fa-inr"></i> 5000    
                                            </Badge> 
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Col>
                                        <Button variant="primary">IDEC Basic <Badge bg="secondary">4</Badge></Button> : 
                                        </Col>
                                            <Col>
                                            <Badge pill bg="info">Module1</Badge> <Badge pill bg="info">12/09/2021</Badge> <Badge pill bg="info">
                                                <i className="fa fa-inr"></i> 5000    
                                            </Badge> <br/>
                                            <Badge pill bg="info">Module 2</Badge>  <Badge pill bg="info">12/09/2021</Badge> <Badge pill bg="info">
                                                <i className="fa fa-inr"></i> 5000    
                                            </Badge><br/>
                                            <Badge pill bg="info">Module 3</Badge>  <Badge pill bg="info">12/09/2021</Badge> <Badge pill bg="info">
                                                <i className="fa fa-inr"></i> 5000    
                                            </Badge><br/>
                                            <Badge pill bg="info">Module 5</Badge>  <Badge pill bg="info">12/09/2021</Badge> <Badge pill bg="info">
                                                <i className="fa fa-inr"></i> 5000    
                                            </Badge><br/>
                                            </Col>
                                    </ListGroupItem>
                                </ListGroup>
                            <TabContent /><br/>
                            </Tab>
                        </Tabs>
                        </Row>
                        <br/>
                    </Container>    
                </>
            ) };
        </>
    )
}
export default StudentDetails;