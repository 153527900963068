import React from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../../Headers/Header';
import { Card } from 'react-bootstrap-v5';
// import { CircularProgressbar } from 'react-circular-progressbar';
// import DashDetails from "./DashDetails";

const mystyle = {
    padding: "10px",
    borderRadius: "25px",
    boxShadow: "0 20px 20px #737dc1",
    backgroundColor: "#f0f3fe",
    borderTop: "3px solid #fff",
    borderLeft: "3px solid #e6e6e6",
    borderBottom: "7px solid #ced2f4"
};

function Dashboard() {

    const loggedin = JSON.parse(localStorage.getItem("adminLoggedin"));
    if(!loggedin) {
        return <Navigate to="/" />;
    }
    return(
        <>
        <Header />
        <div className="container mt-3 shadow px-4 py-2" style={{borderRadius:'20px', backgroundColor:'#767eb69e'}}>
        <div className="row m-1 justify-content-center"style={{borderRadius:'20px',paddingBottom:'30px', backgroundColor:'#fbfbfbe3', boxShadow:'0px 0px -20px #666'}}>
            <span className="h5 text-center mt-2 mb-2">Dashboard Items</span>
            <Card className="text-center p-2 mx-1 col-lg-3 col-md-3 col-sm-6 col-xs-12" style={mystyle}>                
                <Card.Body>
                <h5>Courses</h5>
                    <p>
                        <a className="btn btn-success" href="/courses">View</a>
                    </p>
                </Card.Body>
            </Card>
            <Card className="text-center p-2 mx-1 col-lg-3 col-md-3 col-sm-6 col-xs-12" style={mystyle}>                
                <Card.Body>
                    <h5>Students</h5>
                    <p>
                        <a className="btn btn-success" href="/students">View</a>
                    </p>
                </Card.Body>
            </Card>
            <Card className="text-center p-2 mx-1 col-lg-3 col-md-3 col-sm-6 col-xs-12" style={mystyle}>                
                <Card.Body>
                    <h5>Users</h5>
                    <p>
                        <a className="btn btn-success" href="/users">View</a>
                    </p>
                </Card.Body>
            </Card>
        </div>
        </div>
        </>
    )
}
export default Dashboard;