import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../../Headers/Header';
import LoaderPage from '../LoaderPage';
import { fetchBackendUser, createAdmin, deleteAdmin } from "../../../crud/common.crud";
import { Card,Container,Row,Form,Modal,Button } from 'react-bootstrap-v5';

const BackendUsers =(props) => {

    const loggedin = JSON.parse(localStorage.getItem("adminLoggedin"));
    const [isOpen, setIsOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const [ adminName, setAdminName ]= useState('');
    const [ adminEmail, setAdminEmail ]= useState('');
    const [ adminPhone, setAdminPhone ]= useState('');
    const [ adminPswd, setAdminPswd ]= useState('');
    const [ adminSplzn, setAdminSplzn ]= useState('Admin');
    const [ degree, setDegree ]= useState('');
    const [ position, setPosition ]= useState('');
    const [ location, setLocation ]= useState('');
    
    useEffect(() => {
        fetchBackendUser().then(res => {
            setUsers(res.data);
            setIsDataLoaded(true);
        })
    }, []);
    

    const toggleAddModal = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit=(e) => {
        e.preventDefault();     
            createAdmin(adminName,adminEmail,adminPhone,adminPswd,adminSplzn,degree,position,location).then((res)=>{
            window.location.reload();
        }).catch((err)=>{
        console.log(err);
        });
    };
    
    const delAdmin = (email) => {
        deleteAdmin(email).then((res)=>{
            window.location.reload();
        }).catch((err)=>{
            console.log(err);
        });
    };

    if(!loggedin) {
        return <Navigate to="/" />;
    }
    return(
        <>
            {!isDataLoaded? (
                <LoaderPage />
            ) : (
                <>
                    <Header />
                    <Container>
                        <Row className=" p-2 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                            <Button className="btn btn-success" onClick={toggleAddModal}>New User</Button>
                        </Row>
                        <Row>{
                            users.map(usr =>
                            <Card key={usr._id} className="p-0 m-2 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <Card.Header>
                                    <Row>
                                    <div className="justify-content-around">{usr.adminName}
                                    <div className="float-end">
                                        <Button className="btn btn-danger btn-sm text-white w-100" onClick={() => {if(window.confirm('Are you sure you want to delete the User?')){ delAdmin(usr._id) };}}>
                                            <i className="fa fa-trash"></i>
                                        </Button>
                                    </div>
                                    </div>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    {(usr.adminSplzn==='Teacher')? (
                                        <>
                                            <div><b>Type : </b>{usr.adminSplzn}</div>
                                            <div><b>Degree : </b>{usr.degree}</div>
                                        </>
                                    ) : (
                                        <>
                                            <div><b>Type : </b>{usr.adminSplzn}</div>
                                            <div><b>Email : </b>{usr.adminEmail}</div>
                                            <div><b>Phone : </b>{usr.adminPhone}</div>
                                        </>
                                    )}
                                </Card.Body>
                            </Card>
                            )
                        }</Row>

                        <Modal show={isOpen} onHide={toggleAddModal} >
                            <Modal.Header>
                                <Modal.Title>Add New User</Modal.Title>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit}>
                                <Modal.Body>

                                    <Form.Group className="mb-3" controlId="formType">
                                        <Form.Label>User Type</Form.Label>
                                        <Form.Select type="select" 
                                            value={adminSplzn}
                                            onChange={(e)=>{setAdminSplzn(e.target.value)}} >
                                                <option value="Admin">Super Admin</option>
                                                <option value="Modarator">Modarator</option>
                                                <option value="Teacher">Teacher</option>
                                        </Form.Select>
                                    </Form.Group>
                                    {(adminSplzn==='Teacher')? (
                                        <>
                                            <Form.Group className="mb-3" controlId="formName">
                                                <Form.Label>Teacher Name</Form.Label>
                                                <Form.Control type="text" 
                                                    value={adminName}
                                                    onChange={(e)=>{setAdminName(e.target.value)}}  
                                                    placeholder="Enter Teacher Name" />  
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formName">
                                                <Form.Label>Qualification</Form.Label>
                                                <Form.Control type="text" 
                                                    value={degree}
                                                    onChange={(e)=>{setDegree(e.target.value)}}  
                                                    placeholder="Teacher Qualification" />  
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formName">
                                                <Form.Label>Position</Form.Label>
                                                <Form.Control type="text" 
                                                    value={position}
                                                    onChange={(e)=>{setPosition(e.target.value)}}  
                                                    placeholder="Teacher Position" />  
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formName">
                                                <Form.Label>Location</Form.Label>
                                                <Form.Control type="text" 
                                                    value={location}
                                                    onChange={(e)=>{setLocation(e.target.value)}}  
                                                    placeholder="Location" />  
                                            </Form.Group>
                                        </>
                                    ):(
                                        <>
                                        <Form.Group className="mb-3" controlId="formName">
                                            <Form.Label>User Name</Form.Label>
                                            <Form.Control type="text" 
                                                value={adminName}
                                                onChange={(e)=>{setAdminName(e.target.value)}}  
                                                placeholder="Enter Full Name" />  
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formEmail">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control type="text"
                                                value={adminEmail}
                                                onChange={(e)=>{setAdminEmail(e.target.value)}}  
                                                placeholder="Enter Email Address" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" 
                                            value={adminPswd}
                                            onChange={(e)=>{setAdminPswd(e.target.value)}}  
                                            placeholder="Enter Password"/>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formPhone">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control type="text"
                                                value={adminPhone}
                                                onChange={(e)=>{setAdminPhone(e.target.value)}}  
                                                placeholder="Enter Mobile Number" />
                                        </Form.Group>
                                        </>
                                    )}
                                </Modal.Body>
                        
                                <Modal.Footer>
                                    <Button className="btn btn-danger btn-sm" onClick={toggleAddModal}>Cancel</Button>
                                    <Button className="btn btn-success btn-sm" type="submit">Save</Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>  
                    </Container>    
                </>

            ) };

        </>
    )
}

export default BackendUsers;