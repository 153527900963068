import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../../Headers/Header';
import LoaderPage from '../LoaderPage';
import { fetchCourses, createCourse,deleteCourse } from "../../../crud/common.crud";
import { Card,Container,Row,Form,Modal,Button, Col, Dropdown,ListGroup,Badge } from 'react-bootstrap-v5';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Courses = (props) => {

    const loggedin = JSON.parse(localStorage.getItem("adminLoggedin"));
    const [isOpen, setIsOpen] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [courses, setCourses] = useState([]);
    const [couseName, setCouseName] = useState('');
    const [couseTenure, setCouseTenure] = useState('');
    const [noSingleModule, setNoSingleModule] = useState(false);
    const [cPrice, setCPrice] = useState(0);
    const [sPrice, setSPrice] = useState(0);

    const notify_success = () => toast.success('New Course created successfully.', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
    const notify_crsDel = () => toast.info('Course deleted successfully.', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
    const notify_fail = () => toast.error('Something went wrong. Please try again later.', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
    const asdasd = (slug) => {
        localStorage.setItem('courseSlug', JSON.stringify(slug));
    };
    const delCourse = (slug) => {   
        deleteCourse(slug).then((res)=>{
            notify_crsDel();
            window.location.reload(false);
        }).catch((err)=>{
            console.log(err);
            notify_fail();
        });
    };
    const checkBoxClicked = () => {
        setNoSingleModule(!noSingleModule);
    }
    
    useEffect(() => {
        fetchCourses().then(res => {
            setCourses(res.data);
            setIsDataLoaded(true);
        })
    }, []);

    const toggleCourseModal = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit=(e) => {
        e.preventDefault();     
        createCourse(couseName,couseTenure,noSingleModule,cPrice,sPrice).then((res)=>{
            setIsOpen(!isOpen);
            notify_success();
            setCouseName('');
            setCouseTenure('');
            setNoSingleModule(false);
            setCPrice(0);
            setSPrice(0);
            window.location.reload();
        }).catch((err)=>{
            console.log(err);
            notify_fail();
        });
    };

    if(!loggedin) {
        return <Navigate to="/" />;
    }
    return(
        <>
            {!isDataLoaded? (
                <LoaderPage />
            ) : (
                <>
                    <Header />
                    <Container>
                    <ToastContainer />
                        <Row className="p-2 m-2 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                            <Button className="btn btn-info" onClick={toggleCourseModal}>New Course</Button>
                        </Row>
                        <div className="">
                            {
                                courses?.map(crs =>
                                    <Col key={crs._id} md={3} className="shadow float-start ">
                                        <Card.Header>
                                            <Row>
                                            <div className="justify-content-around">
                                                {/* <i className="fa fa-book rounded-circle bg-light text-center text-primary border border-primary" style={{width:'40px', float:'left', padding:'11px'}}></i> */}
                                                <b>{crs.couseName} </b>
                                                <div className="float-end">
                                                <Dropdown>
                                                    <Dropdown.Toggle split variant="light" id="dropdown-split-basic"/>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            href="/courseDetails" 
                                                            onClick={(e)=>{ asdasd(crs.couseSlug)}}>
                                                            <Button className="btn btn-success btn-sm text-white w-100" >
                                                                <i className="fa fa-arrow-right"></i> 
                                                                &nbsp;&nbsp;Show Details
                                                            </Button>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            href="/courseEdit" 
                                                            onClick={(e)=>{ asdasd(crs.couseSlug)}}>
                                                            <Button className="btn btn-info btn-sm text-white w-100">
                                                                <i className="fa fa-edit"></i>
                                                                &nbsp;Edit 
                                                            </Button>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item 
                                                            onClick={() => {if(window.confirm('Are you sure you want to delete the Course?')){ delCourse(crs.couseSlug) };}}>
                                                            <Button className="btn btn-danger btn-sm text-white w-100">
                                                                <i className="fa fa-trash"></i>
                                                                &nbsp;&nbsp;Delete
                                                            </Button>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                </div>
                                            </div>
                                            </Row>
                                        </Card.Header>                                   
                                        <Card.Body>
                                        <ListGroup variant="flush" className="border shadow rounded text-center">
                                            <ListGroup.Item>
                                                <i className="fa fa-clock-o"></i>
                                                &nbsp;&nbsp;&nbsp;<b>Tenure: <Badge bg="danger">{crs.couseTenure}</Badge> Months</b>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <a style={{textDecoration:'none',color:'#000'}} href="/courseDetails">
                                                <i className="fa fa-list-ul"></i>
                                                &nbsp;&nbsp;&nbsp;<b>Modules : <Badge bg="info">{crs.courseModule.length}</Badge></b>
                                                </a>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <b>Corporate Price : </b>&nbsp;
                                                <i className="fa fa-inr"></i>&nbsp;
                                                {crs.coursePrice.corporatePrice}
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <b>Self Price: </b>&nbsp;
                                                <i className="fa fa-inr"></i>&nbsp;
                                                {crs.coursePrice.selfPrice}
                                            </ListGroup.Item>
                                        </ListGroup>
                                        </Card.Body>
                                    </Col>
                                )
                            }
                        </div>
                        <Modal show={isOpen} onHide={toggleCourseModal} >
                            <Modal.Header>``
                                <Modal.Title>Add New Course</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formCourse">
                                        <Form.Label>Course Name</Form.Label>
                                        <Form.Control type="text" 
                                            value={couseName} required
                                            onChange={(e)=>{setCouseName(e.target.value)}}  
                                            placeholder="Name for the Course" />  
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formTenure">
                                        <Form.Label>Course Tenure in Months</Form.Label>
                                        <Form.Control type="text" 
                                            value={couseTenure} required
                                            onChange={(e)=>{setCouseTenure(e.target.value)}}  
                                            placeholder="Course Tenure in Months" />  
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Row>
                                            <div className="col-6">
                                                <Form.Label>Total Course Corporate Price</Form.Label>
                                                <Form.Control type="number" 
                                                    value={cPrice} min="0" required
                                                    onChange={(e)=>{setCPrice(e.target.value)}}  
                                                    placeholder="Corporate Price" />  
                                            </div> 
                                            <div className="col-6">
                                                <Form.Label>Total Course Self Price</Form.Label>
                                                <Form.Control type="number" 
                                                    value={sPrice} min="0" required
                                                    onChange={(e)=>{setSPrice(e.target.value)}}  
                                                    placeholder="Self Price" />  
                                            </div>
                                        </Row> 
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formTenure">
                                        <Form.Label>By Clicking Can't Choose Single Module</Form.Label>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <input 
                                            type="checkbox" 
                                            value={noSingleModule} 
                                            // onChange={(e)=>{setNoSingleModule(e.target.value)} }
                                            onClick={(e) => { checkBoxClicked()}}
                                        />
                                    </Form.Group>
                        
                                    <Modal.Footer>
                                        <Button className="btn btn-danger btn-sm" onClick={toggleCourseModal}>Cancel</Button>
                                        <Button className="btn btn-success btn-sm" type="submit">Save</Button>
                                    </Modal.Footer>

                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Container>    
                </>

            ) };
        
        </>
    )
}
export default Courses;