import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../../Headers/Header';
import LoaderPage from '../LoaderPage';
import { fetchStudents, createStudent,editStudentPermisn } from "../../../crud/common.crud";
import { Card,Container,Row,Form,Modal,Button,ListGroup } from 'react-bootstrap-v5';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Students = (props) => {

    const loggedin = JSON.parse(localStorage.getItem("adminLoggedin"));
    const [isOpen, setIsOpen] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [students, setStudents] = useState([]);
    const [studentName, setStudentName] = useState('');
    const [studentAddress, setStudentAddress] = useState('');
    const [studentCity, setStudentCity] = useState('');
    const [studentPin, setStudentPin] = useState('');
    // const [studentDob, setStudentDob] = useState('');
    const [studentEmail, setStudentEmail] = useState('');
    const [studentPassword, setStudentPassword] = useState('');
    const [studentPhone, setStudentPhone] = useState('');
    const [studentMedRegNo, setStudentMedRegNo] = useState('');

    // const [btnclicked, setBtnClicked] = useState(true);

    const notify_success = () => toast.success('New Student added successfully.', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
    const notify_fail = () => toast.error('Something went wrong. Please try again later.', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
    
    const enDisStudent = (slug) => {
        editStudentPermisn(slug).then((res)=>{
            console.log(res);
            // window.location.reload();
        }).catch((err)=>{
            console.log(err);
        });
    };
    useEffect(() => {
        fetchStudents().then(res => {
            setStudents(res.data);
            setIsDataLoaded(true);
        })
    }, [students]);
    
    // const delStudent = (email) => {
    //     deleteStudent1(email).then((res)=>{
    //         window.location.reload();
    //     }).catch((err)=>{
    //         console.log(err);
    //     });
    // };
    
    const toggleStudentModal = () => {
        setIsOpen(!isOpen);
    }
    const clickStudentDtl = (email) => {
        localStorage.setItem('stdEmail', JSON.stringify(email));
    }

    const handleSubmit=(e) => {
        e.preventDefault();     
        createStudent(studentName,studentAddress,studentCity,studentPin,studentEmail,studentPassword,studentPhone,studentMedRegNo).then((res)=>{
            setIsOpen(!isOpen);
            notify_success();
        }).catch((err)=>{
            console.log(err);
            notify_fail();
        });
    }

    if(!loggedin) {
        return <Navigate to="/" />;
    }
    return(
        <>
            {!isDataLoaded? (
                <LoaderPage />
            ) : (
                <>
                    <Header />
                    <Container>
                        <ToastContainer />
                        <Row className="p-2 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                            <Button className="btn btn-danger" onClick={toggleStudentModal}>New Student</Button>
                        </Row>
                        <Row>
                            {
                                students?.map(std =>
                                    <Card key={std._id} className="p-0 m-2 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                        <Card.Header>
                                            <Row>
                                            <div className="justify-content-around">
                                                <b>{std.studentName}</b>
                                                <div className="float-end">
                                                            
                                                    <div className="float-end">
                                                    {std.studentPermission ? (
                                                        <Button className="btn btn-success btn-sm text-white w-100" onClick={() => {enDisStudent(std.studentEmail)}}>
                                                        {/* <Button className="btn btn-success btn-sm text-white w-100" onClick={() => {setBtnClicked(false)}}> */}
                                                            <i className="fa fa-toggle-on"></i>
                                                        </Button>
                                                    ):(
                                                        <Button className="btn btn-danger btn-sm text-white w-100" onClick={() => {enDisStudent(std.studentEmail)}}>
                                                        {/* <Button className="btn btn-danger btn-sm text-white w-100" onClick={() => {setBtnClicked(true)}}> */}
                                                            <i className="fa fa-toggle-off"></i>
                                                        </Button>
                                                    )}
                                                    </div>
                                                    <div className="float-end">
                                                        <a href="/StudentDetails" className="btn btn-primary btn-sm text-white w-100"
                                                            onClick={(e) => {clickStudentDtl(std.studentEmail)}}>
                                                            <i className="fa fa-eye"></i> 
                                                        </a>
                                                    </div>
                                               
                                                </div>
                                            </div>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body>
                                            <ListGroup variant="flush" className="border shadow rounded text-center">
                                            <ListGroup.Item>
                                                <i className="fa fa-phone"></i>
                                                &nbsp;&nbsp;&nbsp;<b>Phone: {std.studentPhone.mobile}</b>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <a style={{textDecoration:'none',color:'#000'}} href="/courseDetails">
                                                <i className="fa fa-list-ul"></i>
                                                &nbsp;&nbsp;&nbsp;<b>Register By : {std.source}</b>
                                                </a>
                                            </ListGroup.Item>
                                        </ListGroup>
                                        
                                        </Card.Body>
                                    </Card>
                                )
                            }
                        </Row>

                        <Modal show={isOpen} onHide={toggleStudentModal} >
                            <Modal.Header>
                                <Modal.Title>Add New Student</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formName">
                                        <Form.Label>Student Name</Form.Label>
                                        <Form.Control type="text" 
                                            value={studentName}
                                            onChange={(e)=>{setStudentName(e.target.value)}}  
                                            placeholder="Name for the Student" />  
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formAddress">
                                        <Form.Label>Student Address</Form.Label>
                                        <Form.Control type="text" 
                                            value={studentAddress}
                                            onChange={(e)=>{setStudentAddress(e.target.value)}}  
                                            placeholder="Address of the Student" />  
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Row>
                                            <div className="col-6">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control type="text" 
                                                    value={studentCity}
                                                    onChange={(e)=>{setStudentCity(e.target.value)}}  
                                                    placeholder="City" />  
                                            </div> 
                                            <div className="col-6">
                                                <Form.Label>Pin</Form.Label>
                                                <Form.Control type="text" 
                                                    value={studentPin}
                                                    onChange={(e)=>{setStudentPin(e.target.value)}}  
                                                    placeholder="Pin" />  
                                            </div>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Label>Student Email</Form.Label>
                                        <Form.Control type="email" 
                                            value={studentEmail}
                                            onChange={(e)=>{setStudentEmail(e.target.value)}}  
                                            placeholder="Email Address" />  
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formPassword">
                                        <Form.Label>Student Password</Form.Label>
                                        <Form.Control type="password" 
                                            value={studentPassword}
                                            onChange={(e)=>{setStudentPassword(e.target.value)}}  
                                            placeholder="Password" />  
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formPhone">
                                        <Form.Label>Student Phone</Form.Label>
                                        <Form.Control type="text" 
                                            value={studentPhone}
                                            onChange={(e)=>{setStudentPhone(e.target.value)}}  
                                            placeholder="Phone Number" />  
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formMedical">
                                        <Form.Label>Student Medical Registration No</Form.Label>
                                        <Form.Control type="text" 
                                            value={studentMedRegNo}
                                            onChange={(e)=>{setStudentMedRegNo(e.target.value)}}  
                                            placeholder="Medical Registration No" />  
                                    </Form.Group>
                                    
                                    <Form.Group>
                                        <Button className="btn btn-danger btn-sm" onClick={toggleStudentModal}>Cancel</Button>
                                        <Button className="btn btn-success btn-sm" type="submit">Save</Button>
                                    </Form.Group>

                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Container>   
                </>
            ) };
        </>
    )
}
export default Students;