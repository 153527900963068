import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import readXlsxFile from 'read-excel-file';
// import Files from 'react-files';
import Header from '../../Headers/Header';
import LoaderPage from '../LoaderPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchSingleCourse,addModule,fetchTeacher,addQuestionModule,uploadContent,getCourseModules,deleteModule } from "../../../crud/common.crud";
import { Card,Container,Row,Accordion,Form,Modal,Button,Col,ListGroup,Badge } from 'react-bootstrap-v5';

const CourseDetails =(props) => {

    const loggedin = JSON.parse(localStorage.getItem("adminLoggedin"));
    const slug = JSON.parse(localStorage.getItem("courseSlug"));
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [course, setCourse] = useState({
        _id: '',
        couseName: '',
        couseSlug: '',
        courseModule: [],
        noSingleModule: false,
        coursePrice : {  
            corporatePrice : 0,
            selfPrice : 0
        },
        couseTenure: 0
    });
    const [isOpen, setIsOpen] = useState(false);
    // const [moduleCount, setModuleCount] = useState(0);
    const [courseModules, setCourseModules] = useState([]);
    const [moduleName, setModuleName] = useState('');
    const [predur, setPredur] = useState(0);
    const [postdur, setPostdur] = useState(0);
    const [exitdur, setExitdur] = useState(0);
    const [cPrice, setCPrice] = useState(0);
    const [sPrice, setSPrice] = useState(0);
    const [teachers, setTeachers] = useState([]);
    const [moduleTeacher, setModuleTeacher] = useState('');

    const notify_modDel = () => toast.info('Module deleted successfully.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
    });
    const notify_modAdd = () => toast.info('Module added successfully.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
    });
    const notify_fail = () => toast.error('Something went wrong. Please try again later.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
    });
    useEffect(() => {    
        fetchSingleCourse(slug).then(res => {
            setCourse(res.data);
            setIsDataLoaded(true);
            // setModuleCount(res.data.courseModule.length)
            // console.log(res.data);
        }).catch((err)=>{
            console.log(err);
        });
    }, [slug]);
    useEffect(() => {    
        fetchTeacher().then(res => {
            setTeachers(res.data);
        }).catch((err)=>{
            console.log(err);
        });
    }, []);
    useEffect(() => {    
        getCourseModules(slug).then(res => {
            setCourseModules(res.data);
        }).catch((err)=>{
            console.log(err);
        });
    }, [slug]);

    const toggleModuleModal = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit=(e) => {
        e.preventDefault();  
        const sslg = moduleName.replace(/ /g, '');
        let modID = '00_'+sslg;
        // let modID = moduleCount+'_'+sslg;
        addModule(course._id,slug,course.couseName,modID,moduleName,predur,postdur,exitdur,cPrice,sPrice,moduleTeacher).then((res)=>{
            notify_modAdd();
            window.location.reload();
        }).catch((err)=>{
            console.log(err);
        });
    };
    const delModule = (modID) => {  
        console.log(modID+'deleted'); 
        deleteModule(modID).then((res)=>{
            notify_modDel();
            // window.location.reload(false);
        }).catch((err)=>{
            console.log(err);
            notify_fail();
        });
    };
    const editCourseModuleClick = (modID) => {  
        localStorage.setItem('edit_modID', JSON.stringify(modID));
    };
    const addQuestionSets = (e,modID) => {
        const [file] = e.target.files;
        readXlsxFile(file).then((rows) => { 
            rows.shift();
            let qstnSet = [];
            rows.forEach(function (eachRow, index, array) {
                let arr = {
                    question : eachRow[0],
                    option1 : eachRow[1],
                    option2 : eachRow[2],
                    option3 : eachRow[3],
                    option4 : eachRow[4],
                    answer : eachRow[5]
                };
                qstnSet.push(arr);
            });
            // console.log(qstnSet);
            addQuestionModule(modID,qstnSet).then((res)=>{
                window.location.reload();
            }).catch((err)=>{
                console.log(err);
            });
        });
    };
    // const onFilesChange = (files,modID) => {
    //     console.log(files);
    //     console.log(modID);
    //      // uploadContent(modID,formData, config).then((res)=>{
    //     //     console.log(res.data);
    //     //     // window.location.reload();
    //     // }).catch((err)=>{
    //     //     console.log(err);
    //     // });
    // };
    // const onFilesError = (error, file) => {
    //     console.log('error code ' + error.code + ': ' + error.message);
    // }
    const addContents = (e,modID) => {
        const filee = e.target.files[0];
        
        const formData = new FormData();
        formData.append('file', filee);
        formData.append('fileName', filee.name);
        // const config = {
        //     headers: {
        //         'content-type': 'multipart/form-data',
        //     },
        // };
        // console.log(formData.fileName);
        
        uploadContent(modID,formData).then((res)=>{
            console.log(res.data);
            // window.location.reload();
        }).catch((err)=>{
            console.log(err);
        });
    };

    if(!loggedin) {
        return <Navigate to="/" />;
    }
    return(
        <>
            {!isDataLoaded? (
                <LoaderPage />
            ) : (
                <>
                    <Header /><br/>
                    <div className="bg-primary">
                        <h5 className="text-center text-white p-4">
                            Course: <b className="text-warning">{ course.couseName }</b> |
                            Duration: <b className="text-warning">{ course.couseTenure }</b>(months) |
                            Modules: <b className="text-warning">{course.courseModule.length}</b> 
                            
                        </h5>
                    </div>
                    <Container>
                        <ToastContainer />
                        <Button className="p-2 my-2 btn btn-info" onClick={toggleModuleModal}>New Module</Button>
                        <Row>
                            { 
                                courseModules.length ? (
                                    <Accordion>
                                        {courseModules.map((mdle,i) =>
                                            <Card key={i}>
                                                <Accordion.Toggle as={Card.Header} eventKey={(i+1)}>
                                                    <Row>
                                                        <Col md>{mdle.moduleName}</Col>
                                                        <Col md className="text-center">{mdle.moduleTeacher} | {mdle.moduleContent.length} Contents | {mdle.moduleExam.length} Questions</Col>
                                                        <Col md className="text-end">
                                                            <a className="btn btn-info btn-sm" href="/courseModuleEdit"
                                                                onClick={(e)=>{ editCourseModuleClick(mdle._id)}}>
                                                                <i className="fa fa-edit"></i>  
                                                            </a>&nbsp;
                                                            <Button 
                                                                className="btn btn-danger btn-sm text-white"
                                                                onClick={() => {if(window.confirm('Are you sure you want to delete this Module?')){ delModule(mdle._id) };}}>
                                                                <i className="fa fa-trash"></i>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={(i+1)}>
                                                    <Row>
                                                        <Col md={3}>
                                                            <Card>
                                                                <ListGroup variant="flush">
                                                                    {!course.noSingleModule &&
                                                                        <ListGroup.Item className="text-center p-2">Price</ListGroup.Item>
                                                                    }                                                            
                                                                    <ListGroup.Item className="text-center p-3">Contents</ListGroup.Item>
                                                                    <ListGroup.Item className="text-center p-3">Questions</ListGroup.Item>
                                                                </ListGroup>
                                                            </Card>
                                                        </Col>
                                                        <Col md={9}>
                                                            <Card>
                                                                <ListGroup variant="flush">
                                                                    {!course.noSingleModule &&                         
                                                                        <ListGroup.Item>
                                                                            <Row>
                                                                            <Col>Corporate: {mdle.modulePrice.corporatePrice}</Col>
                                                                            <Col>Self:  {mdle.modulePrice.selfPrice}</Col>
                                                                            </Row>
                                                                        </ListGroup.Item>
                                                                    } 
                                                                    <ListGroup.Item>
                                                                        <Row>
                                                                            <Col>
                                                                                <Form.Group controlId="addModuleContent">                                                                            
                                                                                    <Form.Control 
                                                                                        type="file" 
                                                                                        onChange={(e)=>{addContents(e,mdle._id)}} />  
                                                                                    {/* <Files
                                                                                        className='files-dropzone'
                                                                                        onChange={()=>{onFilesChange(mdle._id)}}
                                                                                        onError={onFilesError}
                                                                                        accepts={['image/png', '.pdf']}
                                                                                        // multiple
                                                                                        maxFileSize={100000}
                                                                                        minFileSize={0}
                                                                                        clickable
                                                                                    >
                                                                                        Drop files here or click to upload
                                                                                    </Files>  */}
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col>
                                                                                <Badge className="fs-6" bg="warning">{mdle.moduleContent.length}
                                                                                </Badge>
                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroup.Item>
                                                                    <ListGroup.Item>
                                                                        <Row>
                                                                            <Col>
                                                                                <Form.Group controlId="formQuestions">                                                                            
                                                                                <Form.Control 
                                                                                    type="file" 
                                                                                    onChange={(e)=>{addQuestionSets(e,mdle._id)}}  
                                                                                    placeholder="Choose excel / csv file" />  
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col>
                                                                                <Badge className="fs-6" bg="secondary">{mdle.moduleExam.length}
                                                                                </Badge>
                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroup.Item>
                                                                </ListGroup>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Collapse>
                                            </Card>                             
                                        )}
                                    </Accordion>
                                ) : (
                                    <div>No module Found</div>
                                )
                            }
                        </Row>


                        <Modal show={isOpen} onHide={toggleModuleModal} >
                            <Modal.Header>
                                <Modal.Title>Add New Module</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formModuleName">
                                        <Form.Label>Module Name</Form.Label>
                                        <Form.Control type="text" 
                                            value={moduleName}
                                            onChange={(e)=>{setModuleName(e.target.value)}}  
                                            placeholder="Name for the Module" />  
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Row>
                                            <div className="col-4">
                                                <Form.Label>Pre Test Duration</Form.Label>
                                                <Form.Control 
                                                    type="number" 
                                                    // value={predur}
                                                    onChange={(e)=>{setPredur(e.target.value)}}  
                                                    placeholder="in months" />  
                                            </div> 
                                            <div className="col-4">
                                                <Form.Label>Post Test Duration</Form.Label>
                                                <Form.Control type="number" 
                                                    value={postdur}
                                                    onChange={(e)=>{setPostdur(e.target.value)}}  
                                                    placeholder="in months" />  
                                            </div>
                                            <div className="col-4">
                                                <Form.Label>Exit Test Duration</Form.Label>
                                                <Form.Control type="number" 
                                                    value={exitdur}
                                                    onChange={(e)=>{setExitdur(e.target.value)}}  
                                                    placeholder="in months" />  
                                            </div>
                                        </Row> 
                                    </Form.Group>
                                    { !course.noSingleModule ? (
                                        <Form.Group className="mb-3">
                                            <Row>
                                                <div className="col-6">
                                                    <Form.Label>Corporate Price</Form.Label>
                                                    <Form.Control 
                                                        type="text" 
                                                        value={cPrice}
                                                        onChange={(e)=>{setCPrice(e.target.value)}}  
                                                        placeholder="Corporate Price" />  
                                                </div> 
                                                <div className="col-6">
                                                    <Form.Label>Self Price</Form.Label>
                                                    <Form.Control type="text" 
                                                        value={sPrice}
                                                        onChange={(e)=>{setSPrice(e.target.value)}}  
                                                        placeholder="Self Price" />  
                                                </div>
                                            </Row> 
                                        </Form.Group>
                                        ) : (<></>)}
                                    <Form.Group className="mb-3" controlId="formModuleTeacher">
                                        <Form.Label>Module Teacher</Form.Label>
                                        <Form.Select aria-label="Default select teacher"
                                            onChange={(e)=>{setModuleTeacher(e.target.value)}}  >
                                            <option>Choose Teacher</option>
                                            {teachers.map((tcr,ti) =>
                                                <option value={tcr.adminName} key={ti}>{tcr.adminName}</option>
                                            )}
                                        </Form.Select>
                                    </Form.Group>
                        
                                    <Modal.Footer>
                                        <Button className="btn btn-danger btn-sm" onClick={toggleModuleModal}>Cancel</Button>
                                        <Button className="btn btn-success btn-sm" type="submit">Save</Button>
                                    </Modal.Footer>

                                </Form>
                            </Modal.Body>
                        </Modal>
                    </Container>    
                </>
            ) };
        </>
    )
}
export default CourseDetails;