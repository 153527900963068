import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../../Headers/Header';
import LoaderPage from '../LoaderPage';
import 'react-toastify/dist/ReactToastify.css';
import { fetchSingleModule,fetchTeacher,updateModuleInfo,fetchSingleCourse } from "../../../crud/common.crud";
import { Container,Row,Form,Button,Col,Badge,Toast,Table } from 'react-bootstrap-v5';

const CourseModuleEdit =(props) => {

    const loggedin = JSON.parse(localStorage.getItem("adminLoggedin"));
    const modID = JSON.parse(localStorage.getItem("edit_modID"));
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [modDtl, setModDtl] = useState({
        pCourseID: '',
        pCourseSlug: '',
        pCourseName: '',
        modID: '',
        moduleName : '',
        modulePrice : {  
            corporatePrice : 0,
            selfPrice : 0
        },
        moduleContent : [ '' ],
        moduleExam : [{  
            question : '',
            option1 : '',
            option2 : '',
            option3 : '',
            option4 : '',
            answer : ''
        }],
        examTime: {
            preTest : 0,
            postTest : 0,
            exitTest : 0
        },
        moduleTeacher: '',
        _id: ''
    });
    const [teachers, setTeachers] = useState([]);
    const [showA, setShowA] = useState(true);
    const [noSingleModule, setNoSingleModule] = useState(false);
    const toggleShowA = () => setShowA(!showA);
    
    useEffect(() => {    
        fetchSingleModule(modID).then(res => {
            setModDtl(res.data[0]);
            setIsDataLoaded(true);
        }).catch((err)=>{
            console.log(err);
        });
    }, [modID]);
    useEffect(() => {    
        fetchTeacher().then(res => {
            setTeachers(res.data);
        }).catch((err)=>{
            console.log(err);
        });
    }, []);

    useEffect(() => {    
        fetchSingleCourse(modDtl.pCourseSlug).then(res => {
            setNoSingleModule(res.data.noSingleModule);   
        }).catch((err)=>{
            console.log(err);
        });
    }, [modDtl.pCourseSlug]);
    const changeQstn = (index,value) => {
        // let newArr = [...modDtl.moduleExam];

        const newArr = modDtl.moduleExam.map((obj,i) => {
            if (i === index) {
              return {...obj, question: value};
            }
          
            return obj;
        });
        setModDtl(prevState => ({
            ...prevState,
            moduleExam:newArr
        }));
    }
    const changeOptn = (index,optn,value) => {
        const newArr = modDtl.moduleExam.map((obj,i) => {
            if (i === index) {
                if(optn==='a'){
                    return {...obj, option1: value};
                } else if(optn==='b'){
                    return {...obj, option2: value};
                } else if(optn==='c'){
                    return {...obj, option3: value};
                } else if(optn==='d'){
                    return {...obj, option4: value};
                }
              
            }
          
            return obj;
        });
        setModDtl(prevState => ({
            ...prevState,
            moduleExam:newArr
        }));
    }
    const changeAns = (index,value) => {
        const newArr = modDtl.moduleExam.map((obj,i) => {
            if (i === index) {
              return {...obj, answer: value};
            }
          
            return obj;
        });
        setModDtl(prevState => ({
            ...prevState,
            moduleExam:newArr
        }));
    }

    const handleSubmit=(e) => {
        e.preventDefault();   
        updateModuleInfo(modID,modDtl).then((res)=>{
            window.location.reload();
        }).catch((err)=>{
            console.log(err);
        });
    };

    if(!loggedin) {
        return <Navigate to="/" />;
    }
    return(
        <>
        {!isDataLoaded? (
            <LoaderPage />
        ) : (
            <>
                <Form onSubmit={handleSubmit}>
                    <Header /><br/>
                    <div className="bg-primary">
                        <div className="position-relative">
                            <div className="position-fixed top-1 start-0">
                            <Button variant="danger" href="/courseDetails"><i className="fa fa-reply"></i> Back</Button>
                            </div>
                            <div className="position-fixed top-1 end-0">
                            <Button className='mb-1' variant="warning" type="submit"><i className="fa fa-edit"></i> Update</Button>
                            </div>
                        </div>
                    <h5 className="text-center text-white p-4">Course Name : <b className="text-danger">{ modDtl.pCourseName }</b> | Module Name : <b className="text-danger">{ modDtl.moduleName }</b> </h5>
                    </div>
                    <Container>
                        <Table bordered hover>
                            <tbody>                    
                                <tr>
                                    <td>Parent Course:</td>
                                    <td>
                                        <Form.Control type="text" value={ modDtl.pCourseName } readOnly/>
                                    </td>
                                    <td>Module Name:</td>
                                    <td>
                                        <Form.Control type="text" 
                                            defaultValue={ modDtl.moduleName } 
                                            onChange={(e)=>{
                                                setModDtl(prevState => ({
                                                    ...prevState,
                                                    moduleName: e.target.value
                                                }))
                                            }} />
                                    </td>
                                </tr>
                                {!noSingleModule &&
                                <tr>
                                    <td colSpan="2">Price:</td>
                                    <td>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Corporate Price</Form.Label>
                                            <Form.Control type="number" 
                                                value={ modDtl.modulePrice.corporatePrice } 
                                                onChange={(e)=>{
                                                    setModDtl(prevState => ({
                                                        ...prevState,
                                                        modulePrice:{
                                                            corporatePrice: e.target.value,
                                                            selfPrice:modDtl.modulePrice.selfPrice
                                                        }
                                                    }));
                                                }} 
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Self Price</Form.Label>
                                            <Form.Control type="number" 
                                                value={ modDtl.modulePrice.selfPrice } 
                                                onChange={(e)=>{
                                                    setModDtl(prevState => ({
                                                        ...prevState,
                                                        modulePrice:{
                                                            corporatePrice: modDtl.modulePrice.corporatePrice,
                                                            selfPrice: e.target.value
                                                        }
                                                    }))
                                                }} 
                                            />
                                        </Form.Group>
                                    </td>
                                </tr>
                                }
                                <tr>
                                    <td>Exam Time:</td>
                                    <td>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Pre Test Time</Form.Label>
                                            <Form.Control type="number" 
                                                value={ modDtl.examTime.preTest } 
                                                onChange={(e)=>{
                                                    setModDtl(prevState => ({
                                                        ...prevState,
                                                        examTime:{
                                                            preTest: e.target.value,
                                                            postTest: modDtl.examTime.postTest,
                                                            exitTest: modDtl.examTime.exitTest
                                                        }
                                                    }));
                                                }} 
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Post Test Time</Form.Label>
                                            <Form.Control type="number" 
                                                value={ modDtl.examTime.postTest } 
                                                onChange={(e)=>{
                                                    setModDtl(prevState => ({
                                                        ...prevState,
                                                        examTime:{
                                                            preTest: modDtl.examTime.preTest,
                                                            postTest: e.target.value,
                                                            exitTest: modDtl.examTime.exitTest
                                                        }
                                                    }));
                                                }} 
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Exit Test Time</Form.Label>
                                            <Form.Control type="number" 
                                                value={ modDtl.examTime.exitTest } 
                                                onChange={(e)=>{
                                                    setModDtl(prevState => ({
                                                        ...prevState,
                                                        examTime:{
                                                            preTest: modDtl.examTime.preTest,
                                                            postTest: modDtl.examTime.postTest,
                                                            exitTest: e.target.value
                                                        }
                                                    }));
                                                }} 
                                            />
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Teacher:</td>
                                    <td>
                                        <Form.Select aria-label="Default select teacher"
                                            value={modDtl.moduleTeacher}
                                            onChange={(e)=>{
                                                setModDtl(prevState => ({
                                                    ...prevState,
                                                    moduleTeacher:e.target.value
                                                }))
                                            }} >
                                            {teachers.map((tcr,ti) =>
                                                <option value={tcr.adminName} key={ti}>
                                                    {tcr.adminName}
                                                </option>
                                            )}
                                        </Form.Select>
                                    </td>
                                    <td colSpan="2">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan="4">
                                    <Button variant="primary">
                                        Contents <Badge bg="secondary">{ modDtl.moduleContent.length }</Badge>
                                    </Button> 
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4">
                                    <Toast show={showA} onClose={toggleShowA} style={{ width: '18%'}}>
                                        <Toast.Header>
                                            <strong className="me-auto">File name</strong>
                                        </Toast.Header>
                                        
                                    </Toast>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4">
                                    <Button variant="primary">
                                        Questions <Badge bg="secondary">{ modDtl.moduleExam.length }</Badge>
                                    </Button> 
                                    </td>
                                </tr>
                                <tr><td colSpan="4">
                                    {modDtl.moduleExam.map((exams,i) =>
                                        <Container key={i}>
                                            <Row>
                                                <Col><b>Question {i+1}:  </b>
                                                    <Form.Control type="text" 
                                                        defaultValue={ exams.question }
                                                        onChange={(e)=>{changeQstn(i,e.target.value)}}  
                                                        />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col><b>(a) </b>
                                                    <Form.Control type="text" 
                                                        defaultValue={ exams.option1 } 
                                                        onChange={(e)=>{changeOptn(i,'a',e.target.value)}}  
                                                    />
                                                </Col>
                                                <Col><b>(b) </b>
                                                    <Form.Control type="text" 
                                                        defaultValue={ exams.option2 } 
                                                        onChange={(e)=>{changeOptn(i,'b',e.target.value)}}  
                                                    />
                                                </Col>
                                                <Col><b>(c) </b>
                                                    <Form.Control type="text" 
                                                        defaultValue={ exams.option3 } 
                                                        onChange={(e)=>{changeOptn(i,'c',e.target.value)}}  
                                                    />
                                                </Col>
                                                <Col><b>(d) </b>
                                                    <Form.Control type="text" 
                                                        defaultValue={ exams.option4 } 
                                                        onChange={(e)=>{changeOptn(i,'d',e.target.value)}}  
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={3}><b>Answer:  </b>
                                                    <Form.Select aria-label="Default select example"
                                                        value={exams.answer}
                                                        onChange={(e)=>{changeAns(i,e.target.value)}}  
                                                    >
                                                        <option value="a">a</option>
                                                        <option value="b">b</option>
                                                        <option value="c">c</option>
                                                        <option value="d">d</option>
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </Container>
                                    )}
                                </td></tr>
                                
                            </tbody>
                            
                        </Table>
                    </Container>   
                </Form> 
            </>
            ) };
        </>
    )
}
export default CourseModuleEdit;