import React, { useState,useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../../Headers/Header';
import { fetchSingleStudent, editStudent1 } from "../../../crud/common.crud";
import { Container,Row,Form,Button } from 'react-bootstrap-v5';

const EditStudent =(props) => {

    const loggedin = JSON.parse(localStorage.getItem("adminLoggedin"));
    const slug = JSON.parse(localStorage.getItem("studentEditSlug"));
    const [student, setStudent] = useState(
        {
            studentName: '',
            studentAddress: {  
                address : '',
                city : '',
                pin : 0
            },
            studentEmail: '',
            source: '',
            studentPhone:  {  
                mobile : 0,
                whatsapp : 0
            },
            studentProfDtls:{  
                medRegNo : '',
                practiceArea : '',
                eduQualification:[]
            }
       }
    );
    
    useEffect(() => {
        fetchSingleStudent(slug).then(res => {
            setStudent(res.data);
        })
    }, [slug]);
    const [studentName, setStudentName] = useState(student.studentName);
    const [studentAddr, setStudentAddr] = useState('');
    const [studentCity, setStudentCity] = useState('');
    const [studentPin, setStudentPin] = useState(0);
    const [studentDob, setStudentDob] = useState('');
    const [studentEmail, setStudentEmail] = useState('');
    const [studentPhone, setStudentPhone] = useState('');
    const [studentMedRegNo, setStudentMedRegNo] = useState('');


    const handleSubmit=(e) => {
        e.preventDefault();     
        editStudent1(slug,studentName,studentAddr,studentCity,studentPin,studentEmail,studentPhone,studentMedRegNo).then((res)=>{
            window.location.reload();
        }).catch((err)=>{
            console.log(err);
        });
    }
    if(!loggedin) {
        return <Navigate to="/" />;
    }
    return(
        <>
            <Header />
            <Container>
                <h2>Edit details for {student.studentName} </h2>
                <Row>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Student Name</Form.Label>
                            <Form.Control type="text" 
                                value={studentName}
                                onChange={(e)=>{setStudentName(e.target.value)}}  
                            />  
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formAddress">
                            <Form.Label>Student Address</Form.Label>
                            <Form.Control type="text" 
                                // defaultValue={studentAddress.address}
                                onChange={(e)=>{setStudentAddr(e.target.defaultValue)}}  
                            />  
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Row>
                                <div className="col-6">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" 
                                        // defaultValue={student.studentAddress.city}
                                        onChange={(e)=>{setStudentCity(e.target.defaultValue)}}  
                                    />  
                                </div> 
                                <div className="col-6">
                                    <Form.Label>Pin</Form.Label>
                                    <Form.Control type="text" 
                                        // defaultValue={student.studentAddress.pin}
                                        onChange={(e)=>{setStudentPin(e.target.defaultValue)}}  
                                    />  
                                </div>
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Student Email</Form.Label>
                            <Form.Control type="email" 
                                defaultValue={student.studentEmail}
                                onChange={(e)=>{setStudentEmail(e.target.defaultValue)}}  
                            />  
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formPhone">
                            <Form.Label>Student Phone</Form.Label>
                            <Form.Control type="text" 
                                // defaultValue={student.studentPhone.mobile}
                                onChange={(e)=>{setStudentPhone(e.target.defaultValue)}}  
                            />  
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formMedical">
                            <Form.Label>Student Medical Registration No</Form.Label>
                            <Form.Control type="text" 
                                // defaultValue={student.studentProfDtls.medRegNo}
                                onChange={(e)=>{setStudentMedRegNo(e.target.defaultValue)}}  
                            />  
                        </Form.Group>
                        
                        <Form.Group>
                            <a href="/students" className="btn btn-danger btn-sm">Cancel</a>
                            <Button className="btn btn-success btn-sm" type="submit">Update</Button>
                        </Form.Group>

                    </Form>
                </Row>
            </Container>    
        </>
    )
}
export default EditStudent;